@import url(./variables.css);

.main {
  position: relative;
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  background: var(--dark);
  transition: 0.5s;
  z-index: 999;
  padding: 10px;
}

.addImage {
  cursor: pointer;
}

.addImage:hover {
  opacity: 85%;
}

.addImage:active {
  opacity: 70%;
}

.sidebar .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: var(--primary);
  color: var(--light);
}

.sidebar .nav-pills .nav-link {
  color: var(--light);
  font-weight: 500;
  padding: 15px 0;
}

.sidebar .toggle-btn {
  display: none;
}

.sidebar .logo img {
  width: 50px;
  margin-bottom: 15px;
}

.sidebar .logo {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
}

.sidebar .logo span {
  font-size: 20px;
  color: var(--light);
  font-weight: 600;
}

.content {
  margin-left: 250px;
  min-height: 100vh;
  background: #f5f0fb;
  transition: 0.5s all ease;
  width: calc(100% - 250px);
  padding: 20px;
}

.sidebar.show {
  margin-left: -250px;

}

.header-box {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 55px;
}

.header-box .dropdown-toggle::after {
  display: none;
}

.toggle-btn i {
  font-size: 25px;
  color: black;
  /* margin-left: 20px; */
}

.header-box .dropdown-menu.show {
  display: block;
  background: var(--light);
  border: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 0px;
}

.header-box .show .dropdown-item {
  padding: 10px 14px;
  transition: 0.5s all ease;
}

.header-box .show .dropdown-item:hover {
  background: var(--primary);
  color: var(--light);
}

.content.show {
  width: 100% !important;
  margin-left: 0;
}


/* //dashboard css */
/* CSS for styling the table */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* Style the table header */
.table th {
  background-color: var(--dark);
  text-align: left;
  color: var(--light);
  padding: 10px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}

/* Style the table rows */
.table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Style alternating rows with a different background color */
.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style the "Update" button */
.btn {
  /* background-color: #007bff; */
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Change button color on hover */
.btn:hover {
  background-color: #0056b3;
}


/* Style the select element */
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

/* Style the select options */
select option {
  padding: 10px;
  background-color: #fff;
  color: #333;
}

/* Style the select options on hover */
select option:hover {
  background-color: #f2f2f2;
}

.table {
  white-space: nowrap;
}

.table-box {
  width: 100%;
  height: 400px;
}

/* .main-body{
    width:calc(100% -280px);
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loader {
  z-index: 110;
}

.main_background {
  background: var(--lightalt);
}

.darkbg {
  background: var(--dark);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.overlay {
  /* position:absolute; */
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.guDipu {
  /* position: absolute; */
  position: fixed;
  overflow-y: scroll;
  top: 38%;
  right: 44%;
  z-index: 90;
}

.jiaKmf {
  animation: 0.75s steps(12) 0s infinite normal none running hDKzZY;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 50;
}

.starStyle {
  color: red;
}

.lableStyle {
  font-size: 16px;
  font-weight: 600;
}

.Airdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.main_background1 {
  background: url("../images/icon-Logo.png");
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  overflow-y: scroll;
  /* overflow: hidden; */
  background-position: center;
  height: 100vh;
  width: 100%;
}

.heading {
  font-family: 'Barlow', sans-serif;
  font-size: 72px;
  /* font-weight: bold; */
  background: -webkit-linear-gradient(#00aeff, #f84242);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 140px;
}

.bg-white {
  --bs-bg-opacity: 1;
  /* background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important; */
  background-color: #3ba7da !important
}

.home {
  justify-content: center;
  text-align: center;
  /* height: 400px; */
}

.bg-light {
  --bs-bg-opacity: 90%;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: rgb(255 255 255 / 90%);
  text-decoration: none;
  white-space: nowrap;
}

.mainDiv {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 992px) {
  .content {
    margin-left: 0;
    width: 100%;
    padding: 20px;
  }

  .sidebar {
    left: -300px;
  }

  .sidebar .toggle-btn {
    display: block;
    color: var(--light);
    display: inline-block;
    color: var(--light);
    background: var(--primary);
    padding: 5px 8px;
    position: absolute;
    right: 0;
    top: 0;

  }

  .sidebar.show {
    left: 0;
    margin-left: 0;
  }
}

.wrapper {
  max-width: 600px;
  width: 100%;
  background: #fff;
  margin: 20px auto;
  box-shadow: 3px 0 10px lightgrey;
  padding: 30px;
  opacity: 90%;

}

.wrapper .title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  color: var(--primary);
  text-transform: uppercase;
  text-align: center;
}

.wrapper .form {
  width: 100%;
}

.wrapper .form .inputfield {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.wrapper .form .inputfield label {
  width: 151px;
  color: #757575;
  margin-right: 10px;
  font-size: 14px;
}

.wrapper .form .inputfield .input,
.wrapper .form .inputfield .textarea {
  width: 100%;
  outline: none;
  border: 1px solid #d5dbd9;
  font-size: 15px;
  padding: 8px 10px;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.wrapper .form .inputfield .textarea {
  width: 100%;
  height: 125px;
  resize: none;
}

.wrapper .form .inputfield .custom_select {
  position: relative;
  width: 100%;
  height: 37px;
}

.wrapper .form .inputfield .custom_select:before {
  content: "";
  position: absolute;
  top: 12px;
  right: 10px;
  border: 8px solid;
  border-color: #d5dbd9 transparent transparent transparent;
  pointer-events: none;
}

.wrapper .form .inputfield .custom_select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 100%;
  border: 0px;
  padding: 8px 10px;
  font-size: 15px;
  border: 1px solid #d5dbd9;
  border-radius: 3px;
  transition: all 0.3s ease;
}


.wrapper .form .inputfield .input:focus,
.wrapper .form .inputfield .textarea:focus,
.wrapper .form .inputfield .custom_select select:focus {
  border: 1px solid var(--primary);
}

.wrapper .form .inputfield p {
  font-size: 14px;
  color: #757575;
}

.wrapper .form .inputfield .check {
  width: 15px;
  height: 15px;
  position: relative;
  display: block;
  cursor: pointer;
}

.wrapper .form .inputfield .check input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.wrapper .form .inputfield .check .checkmark {
  width: 15px;
  height: 15px;
  border: 1px solid var(--primary);
  display: block;
  position: relative;
}

.wrapper .form .inputfield .check .checkmark:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 5px;
  height: 2px;
  border: 2px solid;
  border-color: transparent transparent #fff #fff;
  transform: rotate(-45deg);
  display: none;
}

.wrapper .form .inputfield .check input[type="checkbox"]:checked~.checkmark {
  background: var(--primary);
}

.wrapper .form .inputfield .check input[type="checkbox"]:checked~.checkmark:before {
  display: block;
}

.wrapper .form .inputfield .btn {
  width: 100%;
  padding: 8px 10px;
  font-size: 15px;
  border: 0px;
  background: var(--primary);
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  outline: none;

}

input,
textarea {
  caret-color: var(--primary);
}

.wrapper .form .inputfield .btn1 {
  width: 100%;
  padding: 8px 10px;
  font-size: 15px;
  background: #ffffff;
  color: var(--primary);
  cursor: pointer;
  border-radius: 3px;
  outline: none;
  border: 1px solid;
  border-block-color: var(--primary);
}

.wrapper .form .inputfield .btn1:hover {
  background-color: var(--primary);
  color: white;
  opacity: 90%;
  transition: all 0.3s ease;
}

.wrapper .form .inputfield .btn1:active {
  background-color: var(--primary);
  color: white;
  opacity: 80%;
}

.wrapper .form .inputfield .btn:hover {
  opacity: 80%;
}

.wrapper .form .inputfield .btn:active {
  opacity: 50%;
}

.wrapper .form .inputfield:last-child {
  margin-bottom: 0;
}

.co-ordinates {
  width: 33%;
}

/* .co-ordinate1{
  width:30%;
} */
.cordinateBox {
  width: 100%;
}

/* .cordinateBox{} */
/* .co-ordinates:last-child{
  width: 34%;
} */

@media (max-width:420px) {
  .wrapper .form .inputfield {
    flex-direction: column;
    align-items: flex-start;
  }

  .wrapper .form .inputfield label {
    margin-bottom: 5px;
  }

  .wrapper .form .inputfield.terms {
    flex-direction: row;
  }
}