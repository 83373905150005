@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

:root{
  --primary: #7f30df;
  --secondary: #ef535f;
  --light: #fff;
  --light-alt: #f5f0fb;
  --dark: #230349;
  --dark-alt: #4d4b52;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
::-webkit-scrollbar {
  width: 5px;
  background: var(--dark);
}
::-webkit-scrollbar-thumb {
  background: var(--primary);
}
body{
  background: var(--light-alt);
  padding: 0 0px;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
b,
i,
u,
ul,
ol,
li,
span {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  line-height: 100%;
}

.button-styles{
  display: inline-block;
  padding: 10px 20px;
  border-radius: 6px;
  border: 0;
  outline: 0;
  color: var(--light);
  font-size: 16px;
  background: var(--primary);
  transition: 0.5s all ease;
}

.button-styles:hover{
background: var(--secondary);
}


.form-control, .form-select{
  border-color: #d6d4db;
}

.form-control:focus, .form-select:focus{
  border-color: var(--primary);
  box-shadow: none;
}
